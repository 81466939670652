import PropTypes from "prop-types";
import React from "react";
import ButtonTestModal from "./ButtonTestModal";

const InformationContent = ({ className, title, description }) => {
  return (
    <div className={`text-center ${className}`}>
      <h1 className="font-bold md:mt-8">{title}</h1>
      <h3 className="text-gray py-8">{description}</h3>
      <ButtonTestModal>ENTRAR EM CONTATO</ButtonTestModal>
    </div>
  );
};

InformationContent.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
};

export default InformationContent;
