import React from "react";
import Advert from "../assets/icons/advert.svg";
import Agenda from "../assets/icons/agenda.svg";
import Audience from "../assets/icons/audience.svg";
import Commerce from "../assets/icons/commerce.svg";
import Ctr from "../assets/icons/ctr.svg";
import Email from "../assets/icons/digital-agency.svg";
import Proposals from "../assets/icons/insert.svg";
import Insertion from "../assets/icons/insertion.svg";
import Reports from "../assets/icons/kpi.svg";
import Computer from "../assets/icons/pii.svg";
import Proposta from "../assets/icons/proposta.svg";
import Ron from "../assets/icons/ron.svg";
import SeoWeb from "../assets/icons/seo-and-web.svg";
import Target from "../assets/icons/targeting.svg";
import InformationContent from "../components/InformationContent";
import Layout from "../components/layout";
import ResourcesContent from "../components/ResourcesContent";
import SEO from "../components/seo";

function Recursos() {
  return (
    <Layout>
      <SEO
        keywords={[
          `Biver`,
          `CRM`,
          `Franquias`,
          `Vendedores`,
          `Vendas`,
          `Gestão`,
        ]}
        title="Recursos"
      />
      <div className="text-center mx-5 md:mx-24 xl:mx-64 my-32">
        <h1 className="font-bold md:mt-24">
          Auxiliamos sua empresa em todos os momentos
        </h1>
        <h3 className="text-gray my-8">
          O Biver auxilia sua empresa a solucionar os desafios mais comuns no
          dia-a-dia do relacionamento com seus franqueados.
        </h3>
      </div>

      <div className="background-white py-10">
        <ResourcesContent
          reversed
          title="Gestão de franquias"
          description="Gerencie sua rede de franquias e acompanhe o desempenho detalhadamente de cada uma."
          image={Audience}
        />

        <ResourcesContent
          title="Registro de chamados"
          description="Centralize a abertura de chamados de todas as suas unidades franqueadas e consiga resolver as dúvidas através de um único lugar."
          image={Target}
        />

        <ResourcesContent
          reversed
          title="Gestão de royalties"
          description="Configure a forma de cobrança de royalties de cada unidade franqueada, acompanhe o faturamento e quanto cada uma deverá pagar mensalmente."
          image={Ctr}
        />

        <ResourcesContent
          title="Envio de comunicados"
          description="Envie comunicados, notificações e avisos para toda a sua rede de franquias ou apenas para unidades específicas."
          image={Advert}
        />

        <ResourcesContent
          reversed
          title="Biblioteca de materiais"
          description="Disponibilize materiais de marketing, institucionais e manuais diversos para toda sua rede de franquias."
          image={Insertion}
        />

        <ResourcesContent
          pro
          title="Funil de vendas"
          description="Crie múltiplos funis de venda para cada equipe comercial ou unidade franqueada e tenha controle e visibilidade sobre todo seu processo comercial."
          image={SeoWeb}
        />

        <ResourcesContent
          pro
          reversed
          title="Gestão de tarefas"
          description="Cadastre, categorize e direcione as diversas tarefas para toda sua equipe ou rede franqueada."
          image={Ron}
        />

        <ResourcesContent
          pro
          title="Gestão de propostas"
          description="Acompanhe detalhadamente as propostas abertas e direcione elas para seus vendedores. É possível imprimir uma proposta diretamente pelo sistema e encaminhar por e-mail."
          image={Proposals}
        />

        <ResourcesContent
          pro
          reversed
          title="Produtos e serviços"
          description="Cadastre produtos e serviços com descrições, preços, características e vincule diretamente em suas propostas."
          image={Commerce}
        />

        <ResourcesContent
          pro
          title="Histórico de interação"
          description="Tenha um histórico detalhado em cada proposta com todas as interações que seus vendedores ou franqueadas tiveram com seus clientes."
          image={Computer}
        />

        <ResourcesContent
          pro
          reversed
          title="Envio de e-mails"
          description="Envie e-mails diretamente do CRM e crie modelos padrões para cada tipo de e-mail."
          image={Email}
        />

        <ResourcesContent
          pro
          title="Relatórios"
          description="Relatórios detalhados e úteis para acompanhar todas as informações de desempenho do seu negócio ou de suas unidades franqueadas."
          image={Reports}
        />

        <ResourcesContent
          pro
          reversed
          title="Agenda"
          description="Diariamente, semanalmente ou mensalmente, você consegue ter uma visão ampla das atividades da sua equipe."
          image={Agenda}
        />

        <ResourcesContent
          pro
          title="Proposta comercial"
          description="Utilize um modelo de proposta comercial personalizada para sua empresa e otimize o tempo gasto na geração de novas proposta."
          image={Proposta}
        />
      </div>

      <InformationContent
        className="mx-5 md:mx-24 xl:mx-64 my-32"
        title="Junte-se as diversas empresas que confiam no Biver"
        description="Faça parte das empresas que estão gerenciando sua rede de franquias e acompanhando o desempenho de cada uma."
      />
    </Layout>
  );
}

export default Recursos;
